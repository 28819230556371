<template>
  <div>
    <c-table
      ref="table"
      title="부적합/개선 목록"
      tableId="saasRiskAssess"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      @linkClick="linkClick"
      :merge="grid.merge"
    >
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'risk-assess-impr-tl',
  props: {
    param: {
      type: Object,
      default: () => ({
        riskAssessmentPlanId: '',
        stepCd: '',
      }),
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        assessmentName: '',
        vendorCd: '',
        processCd: '',
        assessVendorId: '',
        ibmStepCd: null,
        actionDeptCd: null,
      },
      grid: {
        merge: [
        ],
        columns: [
          {
            name: 'assessVendorName',
            field: 'assessVendorName',
            label: '업체',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공종',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'assessItemName',
            field: 'assessItemName',
            label: '평가항목',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            label: '평가일',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            label: '평가자',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '개선요청 제목',
            align: 'left',
            type: 'link',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            label: '개선구분',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: '진행상태',
            align: 'center',
            style: 'width:100px',
            sortable: false
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '개선담당업체',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        height: '500px',
        data: [],
      },
      stepItems: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      isPlant: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.saas.resultStatus.vendorlist.url;
      // code setting
      this.$comm.getStepItems('RISK_ASSESS_STEP_CD').then(_result => {
        this.stepItems = _result;
      });
      if (!this.$store.getters.user.plantCds) {
        this.isPlant = false;
      }
      // list setting
      this.getList();
    },
    /* eslint-disable no-unused-vars */
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        riskAssessmentPlanId: this.param.riskAssessmentPlanId
      };
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row, col, index) {
      if (!row || row.ibmClassCd == 'IC00000001') {// 즉시조치
        this.popupOptions.title = '개선 상세'; // 개선 상세
        this.popupOptions.isFull = true;
        this.popupOptions.param = {
          sopImprovementId: row ? row.sopImprovementId : '',
          ibmTaskTypeCd: row ? row.ibmTaskTypeCd : 'ITT9999999',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.isFull = false;
        this.popupOptions.param = {
          sopImprovementId: row ? row.sopImprovementId : '',
          ibmTaskTypeCd: row ? row.ibmTaskTypeCd : 'ITT9999999',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
